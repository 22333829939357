import { legacy_createStore as createStore } from 'redux'
import { HIDE_TOAST, SHOW_TOAST, SUCCESS } from './helper/toast/actions'
import { HIDE_LOADING, SHOW_LOADING } from './helper/loading/actions'

export const SHOW_LOGIN = 'SHOW_LOGIN'
export const HIDE_LOGIN = 'HIDE_LOGIN'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const UPDATE_AVATAR = 'UPDATE_AVATAR'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  toast: {
    type: SUCCESS,
    message: '',
    show: false,
  },
  loading: false,
  showLogin: false,
  isLogin: false,
  avatar: '',
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload }
    case SHOW_TOAST:
      return {
        ...state,
        toast: { show: true, type: action.payload.type, message: action.payload.message },
      }
    case HIDE_TOAST:
      return { ...state, toast: { show: false, message: '' } }
    case SHOW_LOADING:
      return { ...state, loading: true }
    case HIDE_LOADING:
      return { ...state, loading: false }
    case SHOW_LOGIN:
      return { ...state, showLogin: true }
    case HIDE_LOGIN:
      return { ...state, showLogin: false }
    case LOGIN:
      return { ...state, isLogin: true }
    case LOGOUT:
      return { ...state, isLogin: false }
    case UPDATE_AVATAR:
      return { ...state, avatar: action.payload.avatar }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
